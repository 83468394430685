<template>
	<div :class="this.wrapper">
		<label v-if="this.label" :for="this.id" class="form-label" :class="this.labelClass">
			{{ this.label }}<sup v-if="this.required" class="text-danger">*</sup>
		</label>
		<div class="d-flex">
			<div v-if="this.prepend" v-html="this.prepend" class="flex-column text-dark-gray border-0 bg-transparent p-0 pe-3" />
			<div class="selectbox d-flex flex-fill flex-wrap w-100 align-items-center border"
				 :class="[this.class, {
					 'border-success': (this.valid),
					 'border-danger': (this.invalid),
					 'border-brd-primary': (this.valid && this.invalid),
					 'opacity-50': (this.disabled || this.readonly)
				 }]"
				 :data-search="this.search"
				 :data-multiple="this.multiple"
				 aria-expanded="false"
				 ref="selectbox"
				 :data-options-count="this.options.length">
				<div class="d-inline-flex w-100 align-items-center">
					<div class="text-nowrap-mask flex-fill me-auto"
						 style="max-width: 75%;"
						 @click.stop.prevent="this.show = !this.show">
					<span v-if="(!multiple && this.isEmpty(value)) || (multiple && Object.keys(value).length == 0)"
						  class="text-font-black ps-2"
						  v-text="(placeholder) ? placeholder : 'Не выбрано'" />
						<ul v-else-if="(multiple && Object.keys(value).length > 0)" class="d-flex flex-column p-0 m-0">
							<li v-for="(val, index) in value"
								:key="index"
								class="d-flex flex-column me-auto "
								:class="{
									'd-none' : (!this.show && !this.long_list_show && (index+1) > this.long_list_max),
									'mb-2' : value.length >= 2
								}">
								<button :data-id="key"
										:data-value="val"
										type="button"
										class="d-flex align-items-center btn bg-light-gray border border-brd-primary rounded-1 p-10"
										style="height:40px;overflow:hidden;">
									<img :src="require('@/assets/icons/close-icon.svg')"
										 class="d-block me-10"
										 alt="Удалить"
										 width="12"
										 height="12"
										 @click.stop.prevent="removeValue(val)">
									<span class="pe-2" v-text="getOptionName(val, true)"></span>
								</button>
							</li>
							<li v-if="!this.show && Object.keys(value).length > this.long_list_max" class="me-10 mb-2">
								<a href="javascript:{}"
								   class="d-inline-flex align-items-center py-2 pb-1 link link-dashed"
								   @click.stop.prevent="this.long_list_show = !this.long_list_show"
								   v-text="(!this.long_list_show) ? ('ещё ' + this.declOfNumber((Object.keys(value).length - this.long_list_max), ['%d опция', '%d опции', '%d опций'])) : 'свернуть'" />
							</li>
						</ul>
						<span v-else class="text-font-black ps-2" :data-value="value" v-text="getOptionName(value, true)"></span>
					</div>
					<button v-if="Object.keys(value).length > 0 && !(this.disabled || this.readonly)"
							type="button"
							aria-label="Очистить"
							class="selectbox-button d-flex align-items-center justify-content-center btn btn-outline-secondary bg-light-gray border border-brd-primary rounded-1"
							style="width: 44px; height: 44px;"
							@click="this.inputReset()">
						<img :src="require('@/assets/icons/delete.svg')" class="d-block" alt="Очистить" width="14" height="14">
					</button>
					<button v-if="options.length > 0 && !(this.disabled || this.readonly)"
							type="button"
							class="selectbox-button d-flex align-items-center justify-content-center border-0 bg-transparent p-0"
							style="width: 44px; height: 44px;"
							aria-label="Выбрать"
							@click.stop.prevent="this.show = !this.show">
						<img :src="require('@/assets/icons/burger-icon.svg')" class="d-block" aria-current="expand" alt="Развернуть" width="20" height="20">
						<img :src="require('@/assets/icons/burger-collapse-icon.svg')" class="d-block" style="display: none" aria-current="collapse" alt="Свернуть" width="20" height="20">
					</button>
				</div>
				<div v-show="this.show" class="selectbox-list w-100 border-0 border-brd-primary border-top mt-2 px-0">
					<div v-show="this.lazyload || (options.length > 0 && this.search)" class="mb-3">
						<input type="text"
							   v-model="this.query"
							   class="selectbox-search form-control border-0 rounded-0 border-bottom"
							   placeholder="Поиск..."
							   @input="this.$emit('searchInput', this.query)"
							   autofocus
							   autocomplete="off"
							   spellcheck="false" />
					</div>
					<ul v-if="options.length > 0"
						class="list-group"
						ref="list"
						style="padding-right: 6px;max-height: 25vh; overflow-y: auto;"
						@scroll="(e) => this.end_of_list = this.onScrollEnd(e)">
						<li v-for="option in options" :key="option.value">
							<a v-if="option.value !== false" href="javascript:{}"
							   class="border-0 list-group-item list-group-item-action rounded-2 my-1 py-3"
							   :class="isCurrentValue(option.value) ? 'active' : ''"
							   :aria-current="isCurrentValue(option.value)"
							   @click="setValue(option.value)"
							   :data-value="option.value"
							   v-html="option.name" />
							<span v-else
							   class="border-0 list-group-item list-group-item-action rounded-2 my-1 py-3"
							   :aria-current="isCurrentValue(option.value)"
							   :data-value="option.value"
							   v-html="option.name" />
						</li>
						<li v-show="this.lazyload && (this.end_of_list && this.proccessed)" class="text-center px-2">
							<span class="d-inline-flex mx-auto text-dark-gray">Загрузка...</span>
						</li>
					</ul>
				</div>
				<input type="hidden"
					   :id="this.id"
					   :name="this.name"
					   ref="select"
					   v-model="this.value"
					   :disabled="this.disabled"
					   :readonly="this.readonly"
					   :required="this.required" />
			</div>
			<div v-if="append" v-html="append" class="flex-column text-dark-gray border-0 bg-transparent bg-0 p-0 ps-3" />
		</div>
		<div v-if="valid && typeof valid !== 'boolean'" class="d-flex flex-fill valid-feedback" v-text="valid" />
		<div v-else-if="invalid && typeof invalid !== 'boolean'" class="d-flex flex-fill invalid-feedback" v-text="invalid" />
		<div v-if="help" class="form-text" :class="helpClass" v-html="help" />
	</div>
</template>


<script>
import CommonService from "@/services/CommonService";

export default {
	props: {
		inputId: {type: String},
		inputLabel: {type: String},
		inputLabelClass: {type: String},
		inputName: {type: String},
		inputValue: {type: [String, Array]},
		inputOptions: {type: [Array, Object]},
		inputClass: {type: String},
		inputWrapClass: {type: String},
		inputHelpText: {type: String},
		inputHelpClass: {type: String},
		inputPlaceholder: {type: String},
		inputDisabled: {type: [String, Boolean], default: false},
		inputRequired: {type: [String, Boolean], default: false},
		inputReadonly: {type: [String, Boolean], default: false},
		inputLazyLoad: {type: [String, Boolean], default: false},
		inputSearch: {type: [Boolean, String]},
		inputMultiple: {type: [Boolean, String]},
		inputPrepend: {type: String},
		inputAppend: {type: String},
		inputValid: {type: [String, Boolean], default: false},
		inputInValid: {type: [String, Boolean], default: false},
		inputLongListMax: {type: [String, Number]},
		isShown: {type: Boolean},
	},
	data() {
		return {
			id: (typeof this.$props.inputId !== "undefined" && this.$props.inputId) ? this.$props.inputId : '',
			label: (typeof this.$props.inputLabel !== "undefined" && this.$props.inputLabel) ? this.$props.inputLabel : '',
			labelClass: (typeof this.$props.inputLabelClass !== "undefined") ? this.$props.inputLabelClass : 'text-dark-gray',
			name: (typeof this.$props.inputName !== "undefined" && this.$props.inputName) ? this.$props.inputName : '',
			//value: (typeof this.$props.inputValue !== "undefined" && this.$props.inputValue) ? this.$props.inputValue : [],
			//options: (typeof this.$props.inputOptions !== "undefined" && this.$props.inputOptions) ? this.$props.inputOptions : {},
			class: (typeof this.$props.inputClass !== "undefined" && this.$props.inputClass) ? this.$props.inputClass : '',
			wrapper: (typeof this.$props.inputWrapClass !== "undefined" && this.$props.inputWrapClass) ? this.$props.inputWrapClass : '',
			help: (typeof this.$props.inputHelpText !== "undefined" && this.$props.inputHelpText) ? this.$props.inputHelpText : '',
			helpClass: (typeof this.$props.inputHelpClass !== "undefined") ? this.$props.inputHelpClass : '',
			//placeholder: (typeof this.$props.inputPlaceholder !== "undefined" && this.$props.inputPlaceholder) ? this.$props.inputPlaceholder : '',
			//disabled: (typeof this.$props.inputDisabled !== "undefined" && this.$props.inputDisabled === 'true'),
			//required: (typeof this.$props.inputRequired !== "undefined" && this.$props.inputRequired === 'true'),
			//readonly: (typeof this.$props.inputReadonly !== "undefined" && this.$props.inputReadonly === 'true'),
			prepend: (typeof this.$props.inputPrepend !== "undefined") ? this.$props.inputPrepend : false,
			append: (typeof this.$props.inputAppend !== "undefined") ? this.$props.inputAppend : false,
			search: (this.$props.inputSearch == true || this.$props.inputSearch === 'true') ? true : false,
			//multiple: (this.$props.inputMultiple == true || this.$props.inputMultiple === 'true') ? true : false,
			lazyload: (typeof this.$props.inputLazyLoad !== "undefined") ? this.$props.inputLazyLoad : false,
			show: (typeof this.$props.isShown !== "undefined" && this.$props.isShown),
			long_list_show: false,
			long_list_max: (typeof this.$props.inputLongListMax !== "undefined") ? parseInt(this.$props.inputLongListMax) : 3,
			query: '',
			end_of_list: false,
			valid: (typeof this.inputValid !== "undefined") ? this.inputValid : false,
			invalid: (typeof this.inputInValid !== "undefined") ? this.inputInValid : false,
		};
	},
	methods: {
		sortArray(data) {
			return CommonService.sortArray(data);
		},
		isEmpty(data) {
			return CommonService.isEmpty(data);
		},
		inArray(needle, haystack) {
			return CommonService.inArray(needle, haystack);
		},
		declOfNumber(number, words) {
			return CommonService.declOfNumber(number, words);
		},
		showHideList() {

			document.querySelectorAll('.selectbox .selectbox-list').forEach((el) => {
				el.style.display = 'none';
				el.closest('.selectbox').setAttribute('aria-expanded', "false");
			});

			let _this = this;
			window.addEventListener('click', function(e) {
				if (!_this.isEmpty(_this.$refs.selectbox)) {
					if (!_this.$refs.selectbox.contains(e.target)) {
						_this.show = false;
					}
				}
			});

			if (this.$refs.selectbox.hasAttribute('aria-expanded')) {
				if (this.show)
					this.$refs.selectbox.setAttribute('aria-expanded', "true");
				else
					this.$refs.selectbox.setAttribute('aria-expanded', "false");
			}
		},
		onScrollEnd(e) {
			const { scrollTop, offsetHeight, scrollHeight } = e.target
			if ((scrollTop + offsetHeight) >= scrollHeight)
				return true;

			return false;
		},
		setValue: function (value) {
			if (typeof value !== "undefined") {
				if (this.multiple) {
					let index = this.value.indexOf(value);
					if (index == -1) {
						this.value.push(value);
					} else {
						this.value.splice(index, 1);
					}
				} else {
					this.inputReset();
					this.value.push(value);
					
				}
			}

			/*if (!this.multiple)
				this.value = this.value[0];*/

			if (this.multiple)
				this.$emit('setValue', this.value, 'add', value);
			else
				this.$emit('setValue', this.value[0]);

		},
		isCurrentValue: function (value) {
			if (this.inArray(typeof value, ["string", "object", "number"]) && typeof value !== "undefined") {
				let index = this.value.indexOf(value);
				return (index > -1);
			}

			return false;
		},
		removeValue: function (value) {
			if (this.inArray(typeof value, ["string", "object", "number"]) && typeof value !== "undefined") {
				let index = this.value.indexOf(value);
				if (index > -1) {
					this.value.splice(index, 1);
				}
			}

			if (this.multiple)
				this.$emit('setValue', this.value, 'del');
			else
				this.$emit('setValue', this.value[0]);

		},
		getOptionName: function (value, stripTags) {
			let options = this.options;
			let index = Object.keys(options).find(key => options[key].value == value);
			if (index && index != -1) {

				let name = options[index].name;
				if (stripTags)
					return CommonService.stripTags(name.toString());
				else
					return name;

			}

			return;
		},
		inputReset() {
			this.value.length = 0;
			// if (Array.isArray(this.value)) {
				
			// } else {
			// 	this.value = ''
			// }
			this.$props.inputValue = [];
			this.show = false;

			if (this.multiple)
				this.$emit('setValue', []);
			else
				this.$emit('setValue', null);

		},
		validateInput() {
			let select = this.$refs.select;
			if (this.invalid) {
				select.setCustomValidity(this.invalid);
			} else {
				select.setCustomValidity("");
			}
		},
		closeIfClickedOutside(event) {
			if (document.querySelector('.selectbox') && event.target) {

				let need_hide = false;
				if (!document.querySelector('.selectbox').contains(event.target)) {

					if (!(event.target.classList.contains('selectbox-search')))
						need_hide = true;

					if (event.target.classList.contains('list-group-item') && this.multiple)
						need_hide = false;

					if (need_hide) {
						this.show = false;
						document.removeEventListener('click', this.closeIfClickedOutside);
					}
				}
			}
		},
		filtrateInput(value) {

			let list = this.$refs.list;
			if (list && typeof value != "undefined" && !this.isEmpty(value)) {

				let items = list.querySelectorAll('.list-group-item');
				for (let i = 0; i < items.length; i++) {

					let option = items[i];
					let pattern = new RegExp(/[^\w\d\s\u0400-\u04FF\u0021-\u0040]/ig);

					let query = value.toLowerCase().replace(pattern, '');
					option.style.display = 'none';

					if (query.length && (option.innerText.toLowerCase().replace(pattern, '').indexOf(query) !== -1)) {
						option.style.display = 'inline-block';
					}
				}
			}
		}
	},
	async mounted() {
		window.addEventListener('click', this.closeIfClickedOutside);
		this.validateInput();
	},
	watch: {
		'value': function(value, oldValue) {
			this.validateInput(value);
		},
		'show': function(value, oldValue) {
			this.showHideList();
			if (value == true || oldValue == true) {
				this.query = '';
				if (!this.isEmpty(this.$refs.list)) {
					let list_group = this.$refs.list.querySelectorAll('.list-group-item');
					if (typeof list_group !== "undefined") {
						list_group.forEach(item => {
							item.style = ''
						});
					}
				}
			}
		},
		'options': function(value, oldValue) {
			this.proccessed = false;

			if (value.length === oldValue.length)
				this.end_of_list = false;

		},
		'query': function(value, oldValue) {
			this.filtrateInput(value);
		},
		'end_of_list': function(value, oldValue) {
			if (value === true && this.lazyload && !this.proccessed) {
				this.proccessed = true;
				this.$emit('endOfList', Number(this.options.length));
			}

			let _this = this;
			setTimeout(() => {
				_this.end_of_list = false;
			}, 3000);
		}
	},
	computed: {
		value() {

			if (!CommonService.isEmpty(this.$props.inputValue)) {
				if (Object.entries(this.$props.inputValue).length)
					return this.$props.inputValue;
				else
					return [this.$props.inputValue];
			}

			return [];
		},
		options() {

			if (!CommonService.isEmpty(this.$props.inputOptions))
				return this.$props.inputOptions;

			return [];
		},
		disabled() {
			return Boolean(this.$props.inputDisabled);
		},
		required() {
			return Boolean(this.$props.inputRequired);
		},
		readonly() {
			return Boolean(this.$props.inputReadonly);
		},
		multiple() {
			return (this.$props.inputMultiple == true || this.$props.inputMultiple === 'true') ? true : false;
		},
		placeholder() {
			return (typeof this.$props.inputPlaceholder !== "undefined" && this.$props.inputPlaceholder) ? this.$props.inputPlaceholder : '';
		},
	},

}
</script>